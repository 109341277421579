<template>
  <div class="tutorial">
    <div>
      <!-- <quill-editor
        v-html="total_content"
        style="
          width: 100%;
          height: 100%;
          /* min-height: 1000px; */
          background-color: red;
          display: block;
          margin: 20px auto;
        "
      ></quill-editor> -->
      <div class="block-module">
        <a
          class="add-btn"
          href="javascript:void(0)"
          style="display: none"
          @click="click_plus"
          ><i class="iconfont icon-zengjiatianjiajiahao"></i
        ></a>
        <input
          type="text"
          @mouseover="judge_is_show"
          @mouseout="hide"
          @keydown="key_down"
          v-model="content"
          placeholder=""
        />
      </div>
    </div>
    <!-- <div class="header"></div>
    <div class="content"></div> -->
  </div>
</template>

<script type="text/babel">
export default {
  data() {
    return {
      content: "",
      is_show: false,
      content_list: [],
      total_content: "",
    };
  },

  methods: {
    judge_is_show(event) {
      // this.is_show = true;
      // console.log("打印: ", event.target.parentNode);
      event.target.parentNode.querySelector(".add-btn").style =
        "display:inline-block";
    },

    hide(event) {
      // this.is_show = false;
      event.target.parentNode.querySelector(".add-btn").style = "display:none";
    },

    key_down(event) {
      // console.log("按下键盘: ", event.target, event.keyCode);
      let keyCode = event.keyCode;
      let originalElement = event.target;
      if (keyCode == 13) {
        event.target.parentNode.querySelector(".add-btn").style =
          "display:none";
        var input_block = originalElement.parentNode.cloneNode(true);

        // input_block.querySelector(".add-btn").style = "display:none";
        // 为input标签添加点击事件监听器
        input_block.addEventListener("mouseover", this.judge_is_show);

        input_block.addEventListener("mouseout", this.hide);

        input_block.addEventListener("keydown", this.key_down);
        // 将创建的input标签添加到页面的指定位置，例如添加到body中
        event.target.parentNode.insertAdjacentElement("afterend", input_block);

        input_block.querySelector(".add-btn").style = "display:inline-block";
        input_block.querySelector("input").focus();
        // input_block.focus();
      } else if (keyCode == 46) {
      }
    },

    click_plus(event) {
      event.stopPropagation();
      // console.log("打印+号啊");
    },
  },
};
</script>

<style scoped>
input {
  border: none; /* 设置边框 */
  outline: none; /* 去掉轮廓线 */
  padding: 0px; /* 设置内边距 */
  font-size: 25px; /* 设置字体大小 */
  /* background-color: #f9f9f9; 设置背景颜色 */
  background-image: url("http://imgs.ishougongke.com/Public/index/images/common/bg.jpg");
  background-repeat: repeat; /* 设置背景图片重复 */
  display: inline-block;
  width: 100%;
  margin-left: 10px;
}

input:hover {
}

.block-module {
  padding: 0px 20% 0px 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: yellow;
  margin-bottom: 10px;
}
.add-btn {
  display: inline-block;
  /* margin-left: 30px; */
  /* width: 50px; */
  background-color: green;
  font-size: 25px;
}
</style>
